import FAIcon from "@/base-components/icons"
import { LatLngLiteral } from 'google-maps-react-markers'
import React from 'react'

interface MarkerProps
{
  className?: string
  draggable: boolean
  lat: number
  lng: number
  markerId: string
}

const Marker = ({
  className,
  lat,
  lng,
  markerId,
  draggable,
  ...props
}: MarkerProps) =>
  (lat && lng) &&
  (
    <FAIcon type="solid" nameIco='fa-location-dot' customClass={className} />
  )

export default Marker