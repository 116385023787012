'use client';

import GoogleMap from 'google-maps-react-markers'
import { useRef, useState } from 'react'
import Marker from "./marker";
import { ColorProperty, getColor } from "@/utils/color";

const Map = ({ lat, lng, dynamicData }: { lat: number, lng: number, dynamicData?: any }) =>
{
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState<boolean>(false)

  const className = (dynamicData?.data?.marker_color?.value && dynamicData?.data?.marker_color_tint?.value) &&
    getColor(ColorProperty.text,
      dynamicData?.data?.marker_color?.value,
      dynamicData?.data?.marker_color_tint?.value)
    + ' aspect-square w-auto '
    + dynamicData?.data?.marker_size?.value

  return (
    <>
      <GoogleMap
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={Number(dynamicData?.data?.zoom_level?.value ?? 16)}>

        <Marker
          lat={lat}
          lng={lng}
          draggable={false}
          markerId={lat + " " + lng}
          className={className ?? 'text-red h-10 aspect-square w-auto'}
        />

      </GoogleMap>
    </>
  )
}

export default Map