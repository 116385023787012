'use client'

//-->dynamicData
let dynamicData = {
  uid: 'custom-code-plain-js',
  data: {
    "code": {
      sort: 0,
      name: 'JS Code',
      type: 'textarea',
      value: '',
      section: 'content',
    }
  }
}
//<--dynamicData

export default async function CustomCodePlainJS({ data, config }: any)
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: 'addEventListener("DOMContentLoaded", (event) => {' + dynamicData?.data?.code?.value + '});'
        }}
      />
    </>
  )
}
