'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { DsxImage, DsxImageClient } from "@/base-components/image";
import { DsxImageType } from "@/utils/types";
import FAIcon from "@/base-components/icons";
import Link from "next/link";
import 'swiper/css';
import 'swiper/css/pagination';
import { processDynamicLink } from "@/utils/data";
import React from 'react';

export default function MainSlider({ dynamicData, slides }: { dynamicData: any, slides: any })
{

  const button_style: any = {
    outlined: 'border border-white hover:bg-white hover:text-primary-500 active:bg-white',
    filled: 'bg-primary-500 hover:bg-primary-700 active:bg-primary-500',
  }

  return (
    slides?.length === 1
      ?
      <>
        <div className="static flex pt-32 md:pt-64 pb-24 md:pb-56 px-4 sm:px-6 lg:px-0 w-full">

          <div className="text-white mx-auto z-10 flex flex-col items-center max-w-screen-xl">

            {/* Title */}
            <h1 className="text-white text-center mb-4 font-bold capitalize">
              {slides?.at(0)?.title ?? '[Shop Name]'}
            </h1>

            {/* Subtitle */}
            <p className="mb-8 text-xl max-w-lg relaxed">
              {slides?.at(0)?.subtitle ?? 'Serving [City], [State] Since [Year]'}
            </p>

            {/* Primary button */}
            <div className="flex flex-wrap text-center mb-4 sm:mb-0">
              <Link
                href={slides?.at(0)?.primary_button_link
                  ? processDynamicLink(slides?.at(0)?.primary_button_link)
                  : '#make-appointment'}

                className={`${button_style[dynamicData?.data?.button_style?.value]} 
                inline-flex items-center rounded px-8 py-3 font-medium duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

                {slides?.at(0)?.primary_button_text ?? 'Request Appointment'}

              </Link>
            </div>

            {/* Secondary button */}
            {
              slides?.at(0)?.secondary_button_text &&
              <div className={`flex flex-wrap mt-6 underline hover:text-primary-400 decoration-dotted underline-offset-4 hover:underline-offset-8 text-center`}>
                <Link
                  href={processDynamicLink(slides?.at(0)?.secondary_button_link) ?? '/'}

                  className="inline-flex items-center px-8 py-3 font-medium rounded-lg focus:outline-none focus:ring active:border-primary-500 cursor-pointer">

                  {slides?.at(0)?.secondary_button_text}

                </Link>
              </div>
            }
          </div>

          <DsxImageClient dsxImage={slides?.at(0)?.slide_image} />

        </div>
      </>
      :
      <>
        {/* Buttons desktop */}
        <div className="absolute invisible top-1/2 w-full justify-between hidden lg:flex z-10">

          <button className="prev-button bg-white/30 rounded-r p-4 hover:bg-primary-600 duration-300">
            <span className="sr-only">Previous Slide</span>
            <FAIcon type="solid" nameIco='fa-chevron-left' customClass="h-4 w-4" />
          </button>

          <button className="next-button bg-white/30 rounded-l p-4 hover:bg-primary-600 hover:duration-300">
            <span className="sr-only">Next Slide</span>
            <FAIcon type="solid" nameIco='fa-chevron-right' customClass="h-4 w-4" />
          </button>

        </div>

        <Swiper
          autoplay={{
            disableOnInteraction: false,
            delay: (dynamicData?.data?.delay?.value * 1000 ?? 12000),
            pauseOnMouseEnter: true,
          }}
          navigation={{
            nextEl: '.next-button',
            prevEl: '.prev-button',
          }}
          modules={[Autoplay, Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          spaceBetween={0}
          slidesPerView={1}
          breakpoints={{
          }}
          loop={true}>

          <div className="swiper-wrapper">
            {
              slides?.map((slide: any, idx: number) => (
                <SwiperSlide key={idx}>

                  <div className="flex pt-32 md:pt-64 pb-24 md:pb-56 px-4 sm:px-6 lg:px-0 w-full">

                    <div className="mx-auto z-10 flex flex-col items-center max-w-screen-xl">

                      {/* Title */}
                      {
                        idx === 0
                          ?
                          <h1 className="text-white text-center mb-4 font-bold capitalize">
                            {slide?.title ?? '[Shop Name]'}
                          </h1>
                          :
                          <h2 className="text-white text-4xl mb-4 font-bold capitalize">
                            {slide?.title ?? '[Shop Name]'}
                          </h2>
                      }

                      {/* Subtitle */}
                      <p className="mb-8 text-xl max-w-lg relaxed">
                        {slide?.subtitle ?? 'Serving [City], [State] Since [Year]'}
                      </p>

                      {/* Primary button */}
                      <div className="flex flex-wrap text-center mb-4 sm:mb-0">
                        <Link

                          href={slide.primary_button_link
                            ? processDynamicLink(slide.primary_button_link)
                            : '#make-appointment'}

                          className={`${button_style[dynamicData?.data?.button_style?.value]} 
                          inline-flex items-center rounded px-8 py-3 font-medium duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

                          {slide.primary_button_text ?? 'Request Appointment'}

                        </Link>
                      </div>

                      {/* Secondary button */}
                      {
                        slides?.at(0)?.secondary_button_text &&
                        <div className={`flex flex-wrap mt-6 underline hover:text-primary-400 decoration-dotted underline-offset-4 hover:underline-offset-8 text-center`}>
                          <Link
                            href={processDynamicLink(slides?.at(0)?.secondary_button_link) ?? '/'}

                            className="inline-flex items-center px-8 py-3 font-medium rounded-lg focus:outline-none focus:ring active:border-primary-500 cursor-pointer">

                            {slides?.at(0)?.secondary_button_text}

                          </Link>
                        </div>
                      }

                    </div>

                    <DsxImageClient dsxImage={slide.slide_image} />

                  </div>

                </SwiperSlide>
              ))
            }
          </div>

        </Swiper>
      </>
  )
}
