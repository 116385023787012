"use client"

import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { Roboto_Flex } from 'next/font/google'

export const roboto_flex = Roboto_Flex({
	subsets: ['latin'],
	axes: ['wdth']
})

export default function CTA({ data }: any)
{

	const name: string | null | undefined = data?.info?.name;
	const city: string | null | undefined = data?.info?.city;
	const year: string | null | undefined = data?.info?.year_founded;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: `${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`,
	});

	if (!isLoaded) return <p>Loading...</p>;

	return (
		<section className="mx-auto max-w-screen-xl">
			<div className="grid grid-cols-1 grid-rows-2 lg:grid-rows-1 lg:grid-cols-2">

				<Map />

				{/* Content */}
				<div className="bg-primary-200 px-4 sm:px-6 md:px-8 py-16">

					{/* Title */}
					<h2 className={`${roboto_flex.className} font-bold text-2xl sm:text-4xl capitalize mb-4`}>
						Your full-service automotive maintenance and repair center!
					</h2>

					{/* First paragraph */}
					<p className="text-neutral-700 mb-12">
						If you are looking for a local auto repair shop you can trust, consider {name}.
						For reliable, quality, expert automotive repair and personal, friendly customer service?.
						Since {year}, we have been providing our friends and neighbors throughout {city} with dependable, trustworthy auto repairs.
					</p>

					{/* Subtitle */}
					<h3 className={`${roboto_flex.className} text-xl font-bold sm:text-2xl capitalize mb-4`}>
						A True Alternative To The Dealer
					</h3>

					{/* Second paragraph */}
					<p className="text-neutral-700 mb-12">
						Looking for an affordable alternative to the high prices at your local
						dealership? {name} can help. Supported by a team of ASE-Certified technicians who receive ongoing
						training in auto repair services, we`re able to offer you dealership-quality knowledge
						and experience, without dealership pricing.
					</p>

					{/* CTA button */}
					<a
						href="#"
						className="inline-flex items-center bg-primary-500 px-8 py-3 font-medium text-white transition hover:bg-blue-700 focus:outline-none focus:ring focus:ring-yellow-400" >
						Request Appointment
						<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
							<path d="M5 12h14M12 5l7 7-7 7"></path>
						</svg>
					</a>
				</div>

			</div>
		</section>
	)
}

function Map()
{
	const center = useMemo(() => ({ lat: 40, lng: -80 }), []);

	return (
		<GoogleMap
			zoom={12}
			center={{ lat: 40, lng: -80 }}
			mapContainerClassName="google-map-container">

			<Marker position={center}></Marker>

		</GoogleMap>
	)
}