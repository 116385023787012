'use client';

import FAIcon from "@/base-components/icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { review, shop } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";
import { useEffect, useState } from "react";
import 'swiper/css';
import Rating from "@/ui-components/rating";
import Link from "next/link";

//-->dynamicData
let dynamicData = {
  uid: "legacy-reviews-left",
  adapter: "reviews",
  data: {

    // Content
    "title": {
      sort: 1,
      name: 'Title',
      type: 'text',
      value: 'Our Customers Love Us',
      section: 'content',
    },
    "subtitle": {
      sort: 2,
      name: 'Subtitle',
      type: 'text',
      value: 'Here is a few of their latest reviews',
      section: 'cotent',
    },
    "button_text": {
      sort: 3,
      name: 'Button Text',
      type: 'text',
      value: 'Read Our Customers Reviews',
      section: 'content',
    },
    "button_link": {
      sort: 4,
      name: 'Button Link',
      type: 'text',
      value: 'reviews',
      section: 'content',
    },
    'reviews_number': {
      sort: 5,
      name: 'Reviews Number',
      type: 'number',
      value: 4,
      min_num: 1,
      max_num: 16,
      section: 'content'
    },
    'delay': {
      sort: 6,
      name: 'Delay',
      type: 'number',
      value: 12,
      min_num: 4,
      max_num: 32,
      section: 'content'
    },

    // UI
    "paddings": stdComponentDataList('slider', 1, 'Top and Bottom Paddings', 'Padding', 'py', 12),
  },
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  // Array of selected reviews
  const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  // Array with random Google reviews with 5* rating. Used by default
  const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
  useEffect(() =>
  {
    setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 1;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  // Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
  const [random_av_reviews, setAVReviews] = useState<review[]>()
  useEffect(() =>
  {
    setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 2;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  return (
    <section
      id={dynamicData?.uid}
      className={`${dynamicData?.data?.paddings?.value ?? 'py-12'} 
      relative z-10 bg-white mx-4 -mt-12 -mb-12 px-4 sm:px-8 lg:px-12 md:mx-auto max-w-screen-sm lg:max-w-screen-xl rounded shadow-xl`}>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">

        {/* Title */}
        <div className="col-span-1">

          <h2 className="font-bold mb-4 capitalize tracking-tight">
            {dynamicData?.data?.title?.value ?? ''}
          </h2>

          <p className="text-lg mb-4 lg:mb-32">
            {dynamicData?.data?.subtitle?.value ?? ''}
          </p>

          {/* CTA */}
          <div className="flex flex-wrap gap-4 text-center">
            <Link
              href={dynamicData?.data?.button_link?.value ?? '/reviews'}
              className="inline-flex items-center w-full font-medium text-primary-500 hover:text-primary-700 focus:outline-none focus:ring active:text-primary-500 sm:w-auto cursor-pointer">

              {dynamicData?.data?.button_text?.value ?? 'Read Our Customers Reviews'}

              <FAIcon type="solid" nameIco='fa-arrow-right' customClass="text-primary-500 ml-2" />

            </Link>
          </div>

        </div>

        {/* Slider */}
        <div className="lg:col-span-2">

          {/* Blockquote icon */}
          <FAIcon type="solid" nameIco='fa-quote-left' customClass="text-primary-200 text-8xl sm:text-9xl leading-none lg:-mt-4 lg:mb-2" />

          <Swiper
            className="lg:h-3/5"
            autoplay={{
              disableOnInteraction: false,
              delay: (dynamicData?.data?.delay?.value && dynamicData?.data?.delay?.value > 0
                ? dynamicData?.data?.delay?.value * 1000 ?? 12000
                : 1000),
              pauseOnMouseEnter: true,
            }}
            modules={[Autoplay]}
            spaceBetween={16}
            slidesPerView={1}
            breakpoints={{
            }}
            loop={true}>

            {/* Reviews */}
            <div className="swiper-wrapper relative">
              {
                selected_reviews?.length > 0
                  ?
                  // Some reviews are selected in the Harry
                  selected_reviews?.map((review: review, idx: number) =>
                  {
                    return (
                      <SwiperSlide key={idx} className="">
                        <blockquote
                          className="flex flex-col justify-between h-full"
                          key={idx}>

                          {/* Review text */}
                          <p className="w-full line-clamp-4">
                            {review?.content ?? '—'}
                          </p>

                          <div className="flex flex-col mt-4 w-full">

                            <Rating rating={review?.rating ?? 4} color={""} />

                            {/* Author and Location */}
                            <h3 className="text-base font-bold ml-auto capitalize">

                              {"— " + review?.author_name?.toLowerCase() ?? "— Unknown Author"}

                              <span className="font-normal">
                                {review?.location
                                  ? ' from ' + review?.location
                                  : ''}
                              </span>
                            </h3>

                          </div>

                        </blockquote>
                      </SwiperSlide>
                    )
                  })
                  :
                  // No selected reviews in the Harry
                  random_google_reviews && random_google_reviews?.length > 0
                    ?
                    random_google_reviews?.map((review: any, idx: number) =>
                    {
                      return (
                        <SwiperSlide key={idx} className="">
                          <blockquote
                            className="flex flex-col justify-between h-full"
                            key={idx}>

                            {/* Review text */}
                            <p className="w-full line-clamp-4">
                              {review?.content ?? '—'}
                            </p>

                            <div className="flex flex-col mt-4 w-full">

                              <Rating rating={review?.rating ?? 4} color={""} />

                              {/* Author and Location */}
                              <h3 className="text-base font-bold ml-auto capitalize tracking-tight">

                                {"— " + review?.author_name?.toLowerCase() ?? "— Unknown Author"}

                                <span className="font-normal">
                                  {review?.location
                                    ? ' from ' + review?.location
                                    : ''}
                                </span>
                              </h3>

                            </div>

                          </blockquote>
                        </SwiperSlide>
                      )
                    })
                    :
                    random_av_reviews?.map((review: any, idx: number) =>
                    {
                      return (
                        <SwiperSlide key={idx} className="">
                          <blockquote
                            className="flex flex-col justify-between h-full"
                            key={idx}>

                            {/* Review text */}
                            <p className="w-full line-clamp-4">
                              {review?.content ?? '—'}
                            </p>

                            <div className="flex flex-col mt-4 w-full">

                              <Rating rating={review?.rating ?? 4} color={""} />

                              {/* Author and Location */}
                              <h3 className="text-base font-bold ml-auto capitalize">

                                {"— " + review?.author_name?.toLowerCase() ?? "— Unknown Author"}

                                <span className="font-normal">
                                  {review?.location
                                    ? ' from ' + review?.location
                                    : ''}
                                </span>
                              </h3>

                            </div>

                          </blockquote>
                        </SwiperSlide>
                      )
                    })
              }
            </div>

          </Swiper>

        </div>

      </div>

    </section>
  )
}
