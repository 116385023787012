import FAIcon from "@/base-components/icons";

export default function Rating({ rating, color, className = '' }: { rating: number, color: string, className?: string })
{
	const _color = color ?? 'text-[#FEAF00]'

	switch (rating)
	{
		case 1:
			return <div className={`${className} flex leading-none`}>
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
			</div>;

		case 2:
			return <div className={`${className} flex leading-none`}>
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
			</div>

		case 3:
			return <div className={`${className} flex leading-none`}>
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
			</div>

		case 4:
			return <div className={`${className} flex leading-none`}>
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
			</div>

		case 5:
			return <div className={`${className} flex leading-none`}>
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
			</div>

		default:
			<div className={`${className} flex leading-none`}>
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
			</div>
	}
}
