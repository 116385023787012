"use client";

import Link from 'next/link';
import Image from "next/image";
import { useEffect, useState } from 'react';
import FAIcon from "@/base-components/icons";
import { shop } from "@/types/graphql";
import { NavItem } from "@/utils/types";

//-->dynamicData
let dynamicData = {
  uid: "left-bkg_image-nav-social",
  data: {
    'style_slim': {
      sort: 1,
      name: 'Slim navbar',
      type: 'checkbox',
      value: false,
      div: 'ui'
    },
    'style_bg_color': {
      sort: 2,
      name: 'Dark theme',
      type: 'color',
      value: "bg-neutral-100",
      div: 'ui'
    }
  }
};
//<--dynamicData

const dropdownNavs: any = {
  "About": [
    {
      title: "Reviews",
      path: "/"
    },
    {
      title: "Our Blog",
      path: "/"
    },
    {
      title: "Specials",
      path: "/"
    },
    {
      title: "Our Team",
      path: "/"
    },
    {
      title: "FAQ",
      path: "/"
    },
    {
      title: "Contact",
      path: "/"
    },
  ],
  "Landing Pages": [],
  "Locations": [],
  "We Are The Digital Shop": [
    {
      title: "What is SmartFlow?",
      path: "/"
    },
    {
      title: "Digital Inspections",
      path: "/"
    },
  ]
}

export default function Hero({ data, config }: any)
{

  const [state, setState] = useState(false)
  const [DropdownState, setDropdownState] = useState<any>({ isActive: false, idx: null })

  const name: string | null | undefined = data?.info?.name;
  const city: string | null | undefined = data?.info?.city;
  const st: string | null | undefined = data?.info?.state;
  const address: string | null | undefined = data?.info?.address;
  const phone: string | null | undefined = data?.info?.phone_number;
  const year: number | null | undefined = data?.info?.year_founded;
  const primarySpecialty: string | null | undefined = data?.info?.primary_specialty;
  const secondarySpecialty: string | null | undefined = data?.info?.secondary_specialty;

  if (config && config.uid)
  {
    dynamicData = config;
  }

  dropdownNavs["Landing Pages"] = data?.landings?.map((x: NavItem) =>
  {
    x.title = x.name;
    x.path = `/landing/${x.slug}`;
    return x
  }) ?? [];

  dropdownNavs["Locations"] = data?.locations;


  const navigation = [
    { title: "Home", path: "/", isDropdown: false },
    { title: "Services", path: "/services", isDropdown: false },
    { title: "Service Videos", path: "service-videos", isDropdown: false },
    { title: "About", path: "#", isDropdown: true, dropNavs: dropdownNavs },
    { title: "Contact", path: "#", isDropdown: false }
  ]

  useEffect(() =>
  {
    document.onclick = (e) =>
    {
      const target = e.target as HTMLElement;
      if (!target.closest(".nav-menu")) setDropdownState({ isActive: false, idx: null });
    };
  }, [])

  return (
    <section className="w-full bg-[url(https://source.unsplash.com/KxeFuXta4SE)] bg-cover bg-center bg-no-repeat">
      <div className="relative md:pt-8 md:pb-24 text-white bg-neutral-900/40">

        {/* Address */}
        <div className="relative text-white z-20 w-full md:static md:text-xs md:border-none">
          <div className="items-center max-w-screen-xl mx-auto hidden md:flex md:px-8">
            <div className="flex items-center w-full justify-between pb-4">

              {/* Phone */}
              <a href="#" rel="noopener noreferrer" className="items-center gap-2 md:flex">
                <FAIcon type="solid" nameIco='fa-mobile-screen' customClass="h-4" />
                <span className="hover:underline focus-visible:underline">{phone}</span>
              </a>

              {/* Address */}
              <a href="#" rel="noopener noreferrer" className="items-center gap-2 md:flex">
                <FAIcon type="regular" nameIco='fa-map' customClass="h-4" />
                <span className="hover:underline focus-visible:underline">{address + ', ' + city + ', ' + st}</span>
              </a>

              {/* Scedule */}
              <a href="#" rel="noopener noreferrer" className="items-center gap-2 md:flex">
                <FAIcon type="regular" nameIco='fa-calendar' customClass="h-4" />
                <span className="hover:underline focus-visible:underline">Sun–Mon: 12.00–18.00, Tue–Fri: 9.00–19.00, Sat: 10.00–18.00</span>
              </a>

            </div>
          </div>

        </div>

        {/* Navigation */}
        <>
          <nav className={`bg-white/20 shadow-xl backdrop-blur-sm border border-white/30 relative max-w-screen-xl mx-auto z-20 w-full md:static md:text-sm ${st ? "shadow-lg rounded-lg md:shadow-none" : ""}`}>
            <div className="justify-between items-center px-4 md:px-8 md:flex">
              <div className={`flex items-center justify-between ${dynamicData.data?.style_slim.value ? "py-1 md:py-2" : "py-3 md:py-5"} md:block`}>

                {/* Logo */}
                <Link href="/" title="DSX Next Prototype" aria-label="DSX Next Prototype">
                  <Image
                    width={dynamicData.data?.style_slim.value ? "144" : "214"}
                    height={dynamicData.data?.style_slim.value ? "32" : "48"}
                    src={"/images/autovitals-white.svg"}
                    alt={"Logotype"}
                  />
                </Link>

                {/* Mobile burger */}
                <div className="md:hidden">
                  <button className="text-neutral-500 hover:text-neutral-800" aria-label="Main navigation" title="Main navigation"
                    onClick={() => setState(!st)}
                  >
                    {
                      st ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                          <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                        </svg>
                      )
                    }
                  </button>
                </div>
              </div>

              <hr className="md:hidden max-w-screen-xl mx-auto border-neutral-300 dark:border-neutral-700" />

              <div className={`nav-menu pb-4 mt-8 md:block md:pb-0 md:mt-0 ${st ? 'block' : 'hidden'}`}>
                <ul className="items-center space-y-6 md:flex md:space-x-6 md:space-y-0">

                  {/* Menu */}
                  {
                    navigation.map((item, idx) =>
                    {
                      return (
                        <li key={idx}>
                          {
                            item.isDropdown ? (
                              <button
                                className="w-full flex items-center justify-between gap-1 text-white hover:underline hover:underline-offset-4"
                                onClick={() => setDropdownState({ isActive: !DropdownState.isActive, idx: idx })}
                              >
                                {item.title}
                                {
                                  DropdownState.idx == idx && DropdownState.isActive ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                      <path fillRule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clipRule="evenodd" />
                                    </svg>

                                  ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                      <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                  )
                                }
                              </button>
                            ) : (
                              <a href={item.path} className="block text-white hover:underline hover:underline-offset-4">
                                {item.title}
                              </a>
                            )
                          }
                          {
                            item.isDropdown && DropdownState.idx == idx && DropdownState.isActive ? (
                              <div className="mt-6 inset-x-0 z-10 bg-white w-full md:absolute md:border-y md:shadow-md">
                                <ul className='max-w-screen-xl mx-auto grid items-top gap-6 md:p-8 md:grid-cols-2 lg:grid-cols-4'>
                                  {
                                    Object.keys(item.dropNavs).map((k: string, index: number) =>
                                    {
                                      let nItem = item.dropNavs[k];
                                      return (
                                        <li key={k}>
                                          <p className="text-blue-600 text-lg"><b>{k}</b></p>
                                          <ul className='mt-5 space-y-3'>
                                            {nItem.map((navItem: any, idx: number) => (
                                              <li key={idx}>
                                                <a href={navItem.path} className='flex gap-3 items-center'>{navItem.title}</a>
                                              </li>
                                            ))}
                                          </ul>
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            ) : ""
                          }
                        </li>
                      )
                    })
                  }

                  <hr className="max-w-screen-xl mx-auto border-neutral-300 dark:border-neutral-700" />

                  {/* Mobile contacts */}
                  <div className='flex flex-wrap sm:flex-nowrap text-neutral-500 text-xs justify-between space-y-3 sm:space-y-0 md:hidden md:space-y-0'>
                    <a href="#" rel="noopener noreferrer" className="md:items-center items-top gap-2 flex w-full sm:w-1/4">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-neutral-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                      </svg>
                      <span className="hover:underline focus-visible:underline">{phone}</span>
                    </a>
                    <a href="#" rel="noopener noreferrer" className="md:items-center items-top gap-2 flex w-full sm:w-1/3">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-neutral-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                      </svg>
                      <span className="hover:underline focus-visible:underline">{address + ', ' + city + ', ' + st}</span>
                    </a>
                    <a href="#" rel="noopener noreferrer" className="md:items-center items-top gap-2 flex w-full sm:w-max">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-neutral-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                      </svg>
                      <span className="hover:underline focus-visible:underline">Sun–Mon: 12.00–18.00, Tue–Fri: 9.00–19.00, Sat: 10.00–18.00</span>
                    </a>
                  </div>

                </ul>
              </div>

              {/* Button */}
              <div className={`items-center ${dynamicData.data?.style_slim.value ? "py-2" : "py-8"} md:space-y-0 md:block ${st ? 'block' : 'hidden'}`}>
                <a href="#" className={`block ${dynamicData.data?.style_slim.value ? "py-2" : "py-3"} px-4 font-medium text-center border border-white/40 hover:border-white/60 active:bg-white/30 rounded-lg focus:outline-none focus:ring`}>
                  Call Us
                </a>
              </div>

            </div>
          </nav>
          {
            st ? (
              <div
                className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
                onClick={() => setState(false)}></div>
            ) : ""
          }
        </>

        {/* Hero */}
        <div className="w-full h-full">

          <div className="relative mx-auto max-w-screen-xl px-4 md:pt-36 sm:px-6 lg:flex lg:px-8">

            {/* Left part */}
            <div className="md:pr-8 md:w-1/2 ltr:sm:text-left rtl:sm:text-right">

              {/* Title */}
              <h1 className="mb-4 text-6xl font-bold md:text-7xl sm:text-5xl capitalize">
                {name}
              </h1>

              {/* CTA */}
              <div className="flex flex-wrap text-center">
                <a href="#"
                  className="inline-flex items-center bg-primary-400/30 shadow-xl backdrop-blur-sm border border-primary-400/30 rounded-lg px-8 py-3 text-primary-400 hover:border-primary-400/60 hover:text-primary-300 focus:outline-none focus:ring active:bg-primary-500 sm:w-auto cursor-pointer">
                  Request Appointment
                  <FAIcon type="solid" nameIco='fa-arrow-right' customClass="h-4 text-primary-400 ml-1" />
                </a>
              </div>

              {/* Social */}
              <div className="flex mt-36 -mx-2 ">

                <a className="mx-2 text-white transition-colors duration-300 transform hover:text-primary-500" href="#">
                  <FAIcon type="brands" nameIco='fa-twitter' customClass="h-6" />
                </a>

                <a className="mx-2 text-white transition-colors duration-300 transform hover:text-primary-500" href="#">
                  <FAIcon type="brands" nameIco='fa-facebook' customClass="h-6" />
                </a>

                <a className="mx-2 text-white transition-colors duration-300 transform hover:text-primary-500" href="#">
                  <FAIcon type="brands" nameIco='fa-square-instagram' customClass="h-6" />
                </a>

                <a className="mx-2 text-white transition-colors duration-300 transform hover:text-primary-500" href="#">
                  <FAIcon type="brands" nameIco='fa-youtube' customClass="h-6" />
                </a>

              </div>

            </div>

            {/* Right part */}
            <div className="w-1/2 pt-16 flex flex-col content-start items-end justify-between">

              <div className="">

                {/* Subtitle */}
                <h2 className="mb-4 font-bold text-2xl sm:text-3xl capitalize">
                  Quality Auto Repair in {city}, {st}
                </h2>

                {/* Description */}
                <p className=" text-white sm:text-xl/relaxed">
                  Think of {name} when you need preventative maintenance like {primarySpecialty} and {secondarySpecialty}. We will perform all repair jobs in a timely and efficient manner, so you can get back on the road to more important things.
                </p>

              </div>

              {/* Reviews */}
              <a href="#">
                <div className="flex items-center">

                  <FAIcon type="solid" nameIco='fa-star' customClass="h-4 text-white ml-1" />
                  <FAIcon type="solid" nameIco='fa-star' customClass="h-4 text-white ml-1" />
                  <FAIcon type="solid" nameIco='fa-star' customClass="h-4 text-white ml-1" />
                  <FAIcon type="solid" nameIco='fa-star' customClass="h-4 text-white ml-1" />
                  <FAIcon type="solid" nameIco='fa-star' customClass="h-4 text-white ml-1" />

                  <p className="ml-2 text-sm underline underline-offset-4 text-white dark:text-neutral-400">
                    160 Reviews
                  </p>

                </div>
              </a>

            </div>

          </div>
        </div>
      </div>
    </section>
  )
}
