"use client";

import FAIcon from "@/base-components/icons";
import { DsxImageClient } from "@/base-components/image";
import { shop } from "@/types/graphql";
import { ColorProperty, getBgColor, getColor, getTextColor } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import { ShopColors, DsxImageType } from "@/utils/types";
import Link from "next/link";
import { useState } from "react";

//-->dynamicData
let dynamicData = {
  uid: 'banner-left-icon-button',
  adapter: "banner",
  data: {

    // UI
    "paddings": stdComponentDataList('slider', 1, 'Paddings', 'Padding', 'py', 6),
  }
}
//<--dynamicData

export default function Banner({ data, images, config }: { data: shop, images: DsxImageType, config: any })
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  const [state, setState] = useState(true);

  const banner_bg_color: ShopColors = {
    primary: 'bg-primary-500',
    secondary: 'bg-secondary-500',
    neutral: 'bg-neutral-500',
    unset: 'bg-white',
  }

  const text_color: ShopColors = {
    primary: 'text-primary-500',
    secondary: 'text-secondary-500',
    neutral: 'text-neutral-900',
    unset: 'text-white',
  }

  const button_colors: ShopColors = {
    primary: 'bg-primary-500 hover:bg-primary-600 active:bg-primary-600 text-white',
    secondary: 'bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-600 text-white',
    neutral: 'bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-600 text-white',
    unset: 'bg-white hover:bg-neutral-50 active:bg-neutral-50 text-primary-500'
  }

  if (data?.info?.special_message_enabled)
  {

    return (
      <div className={state
        ? 'block'
        : 'hidden'}>

        <div className={getColor(
          ColorProperty.background,
          data?.info?.special_message_color ?? 'unset',
          500)}>

          <div className={`${dynamicData?.data?.paddings?.value ?? 'py-6'}
          flex flex-wrap md:flex-nowrap px-4 sm:px-6 xl:px-0 py-4 mx-auto max-w-screen-xl items-center justify-between`}>

            <div className="flex w-full mb-4 md:mb-0 space-x-2 md:mr-4">

              {/* Icon */}
              {
                data?.info?.special_message_image_id &&
                <div className={getColor(
                  ColorProperty.text,
                  data?.info?.special_message_text_color ?? 'neutral',
                  text_color)}>
                  <DsxImageClient dsxImage={images} />
                </div>
              }

              {/* Content */}
              <div className="w-full line-clamp-3 md:line-clamp-2" dangerouslySetInnerHTML={{ __html: data?.info?.special_message_content ?? '' }} />

            </div>

            <div className="flex w-full md:w-auto justify-between items-center">

              {/* CTA */}
              {
                (data?.info?.special_message_button_text && data?.info?.special_message_button_url) &&
                <Link
                  className={`${data?.info?.special_message_button_color && data?.info?.special_message_button_color.includes('custom')
                    ?
                    getColor(
                      ColorProperty.background,
                      data?.info?.special_message_button_color ?? 'unset',
                      button_colors) + ' text-white'
                    :
                    getColor(
                      ColorProperty.background,
                      data?.info?.special_message_button_color ?? 'unset',
                      button_colors)
                    } inline-flex whitespace-nowrap text-center items-center rounded px-8 py-3 font-medium focus:outline-none focus:ring cursor-pointer`}
                  href={data?.info?.special_message_button_url ?? '/specials'}>

                  {data?.info?.special_message_button_text ?? 'Our Specials'}

                  <FAIcon type="solid" nameIco='fa-arrow-right' customClass="w-4 h-4 ml-2" />

                </Link>
              }

              {/* Close button */}
              {
                data?.info?.special_message_display_close_button &&
                <button
                  onClick={() => setState(false)}
                  className={`${getColor(
                    ColorProperty.text,
                    data?.info?.special_message_text_color ?? 'unset',
                    text_color)} p-2 ring-offset-2 leading-none focus:ring`}
                  aria-label="Close button">

                  <FAIcon type="solid" nameIco='fa-xmark' customClass="w-6 h-6" />

                </button>
              }
            </div>

          </div>

        </div>

      </div >
    )

  }

}