'use client'

//-->dynamicData
let dynamicData = {
  uid: 'custom-code-plain-html',
  data: {
    "code": {
      sort: 0,
      name: 'HTML Code',
      type: 'textarea',
      value: '',
      section: 'content',
    }
  }
}
//<--dynamicData

export default async function CustomCodePlainHTML({ data, config }: any)
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: dynamicData?.data?.code?.value
        }}
      />
    </>
  )
}
