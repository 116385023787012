'use client';

import FAIcon from "@/base-components/icons";
import { review, shop } from "@/types/graphql";
import Rating from "@/ui-components/rating";
import { stdComponentDataList } from "@/utils/std-component-data";
import Link from "next/link";
import { useEffect, useState } from "react";

//-->dynamicData
let dynamicData = {
  uid: "reviews-left-6-full",
  adapter: "reviews",
  data: {

    // Content
    "title": {
      sort: 1,
      name: 'Title',
      type: 'text',
      value: 'Reviews from Customers in [County] County',
      section: 'content',
    },
    "button_text": {
      sort: 3,
      name: 'Button Text',
      type: 'text',
      value: 'More From Our Customers',
      section: 'content',
    },
    "button_link": {
      sort: 4,
      name: 'Button Link',
      type: 'text',
      value: 'reviews',
      section: 'content',
    },
    'reviews_number': {
      sort: 5,
      name: 'Reviews Number',
      type: 'number',
      value: 9,
      min_num: 3,
      max_num: 18,
      section: 'content'
    },
    'delay': {
      sort: 6,
      name: 'Delay',
      type: 'number',
      value: 12,
      min_num: 4,
      max_num: 32,
      section: 'content'
    },

    // UI
    "paddings": stdComponentDataList('slider', 1, 'Top and Bottom Paddings', 'Padding', 'py', 20),
  },
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  // Array of selected reviews
  const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  // Array with random Google reviews with 5* rating. Used by default
  const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
  useEffect(() =>
  {
    setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 1;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  // Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
  const [random_av_reviews, setAVReviews] = useState<review[]>()
  useEffect(() =>
  {
    setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 2;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  return (
    <section
      id={dynamicData?.uid}
      className={`${dynamicData?.data?.paddings?.value ?? 'py-12'} 
      dark:bg-neutral-900`}>

      <div className="w-full mx-auto xl:max-w-screen-xl px-4 md:px-6 lg:px-8 xl:px-0">

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-12 items-end justify-between">

          {/* Title */}
          <h2 className="font-bold mb-4 capitalize tracking-tight dark:text-white antialiased">
            {dynamicData?.data?.title?.value ?? ''}
          </h2>

        </div>

        <div className="grid gap-y-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-12">
          {
            selected_reviews?.length > 0
              ?
              selected_reviews?.map((review: any, idx: number) => (

                <blockquote
                  key={idx}
                  className="flex h-full flex-col justify-between sm:pr-16">

                  <div className="mb-2 md:mb-6">

                    <Rating rating={review?.rating ?? 4} color={""} />

                    <div className="mt-4">
                      <p className="mb-2 text-neutral-600 line-clamp-5">
                        {review?.content
                          ? review?.content
                          : '—'}
                      </p>
                    </div>

                  </div>

                  <footer className="text-neutral-400">
                    {review.author_name
                      ? "— " + review.author_name
                      : "— Unknown Author"}
                  </footer>

                </blockquote>

              ))
              :
              random_google_reviews && random_google_reviews?.length > 0
                ?
                random_google_reviews?.map((review: any, idx: number) =>
                  <blockquote
                    key={idx}
                    className="flex h-full flex-col justify-between sm:pr-16">

                    <div className="mb-2 md:mb-6">

                      <Rating rating={review?.rating ?? 4} color={""} />

                      <div className="mt-4">
                        <p className="mb-2 text-neutral-600 line-clamp-5">
                          {review?.content
                            ? review?.content
                            : '—'}
                        </p>
                      </div>

                    </div>

                    <footer className="text-neutral-400">
                      {review.author_name
                        ? "— " + review.author_name
                        : "— Unknown Author"}
                    </footer>

                  </blockquote>
                )
                :
                random_av_reviews?.map((review: any, idx: number) =>
                  <blockquote
                    key={idx}
                    className="flex h-full flex-col justify-between sm:pr-16">

                    <div className="mb-2 md:mb-6">

                      <Rating rating={review?.rating ?? 4} color={""} />

                      <div className="mt-4">
                        <p className="mb-2 text-neutral-600 line-clamp-5">
                          {review?.content
                            ? review?.content
                            : '—'}
                        </p>
                      </div>

                    </div>

                    <footer className="text-neutral-400">
                      {review.author_name
                        ? "— " + review.author_name
                        : "— Unknown Author"}
                    </footer>

                  </blockquote>
                )
          }
        </div>

        {/* Primary button */}
        <div className="flex flex-wrap gap-4 text-center text-white">
          <Link
            href={dynamicData?.data?.button_link?.value ?? '/reviews'}
            className="inline-flex items-center w-full px-8 py-4 font-medium bg-primary-500 hover:bg-primary-600 rounded-sm focus:outline-none focus:ring active:bg-primary-600 sm:w-auto cursor-pointer">

            {dynamicData?.data?.button_text?.value ?? 'Read Our Customers Reviews'}

            <FAIcon type="solid" nameIco='fa-arrow-right' customClass="ml-2" />

          </Link>
        </div>

      </div>

    </section>

  )
}
